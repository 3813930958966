let resumeData = {
  imagebaseurl: "https://amrutha97.github.io/",
  name: "Amrutha Gujjar",
  role: "Co-Founder @ Structured",
  linkedinId: "Your LinkedIn Id",
  skypeid: "Your skypeid",
  roleDescription:
    "Prev Senior Software Engineer @ Meta | Computer Science @ University of Washington",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/amruthagujjar/",
      className: "fa fa-linkedin",
    },
    {
      name: "github",
      url: "http://github.com/amrutha97",
      className: "fa fa-github",
    },
    {
      name: "skype",
      url: "http://twitter.com/amruthagujjar",
      className: "fa fa-twitter",
    },
  ],
  aboutme:
    "Prev Senior Software Engineer @ Meta | Computer Science @ University of Washington",
  address: "USA",
  website: "https://amruthagujjar.com",
  education: [
    {
      UniversityName: "The LNM Insitute of Information Technology",
      specialization: "Some specialization",
      MonthOfPassing: "Aug",
      YearOfPassing: "2020",
      Achievements: "Some Achievements",
    },
    {
      UniversityName: "Some University",
      specialization: "Some specialization",
      MonthOfPassing: "Jan",
      YearOfPassing: "2018",
      Achievements: "Some Achievements",
    },
  ],
  work: [
    {
      CompanyName: "Some Company",
      specialization: "Some specialization",
      MonthOfLeaving: "Jan",
      YearOfLeaving: "2018",
      Achievements: "Some Achievements",
    },
    {
      CompanyName: "Some Company",
      specialization: "Some specialization",
      MonthOfLeaving: "Jan",
      YearOfLeaving: "2018",
      Achievements: "Some Achievements",
    },
  ],
  skillsDescription: "Your skills here",
  skills: [
    {
      skillname: "HTML5",
    },
    {
      skillname: "CSS",
    },
    {
      skillname: "Reactjs",
    },
  ],
  portfolio: [
    {
      name: "project1",
      description: "mobileapp",
      imgurl: "images/portfolio/phone.jpg",
    },
    {
      name: "project2",
      description: "mobileapp",
      imgurl: "images/portfolio/project.jpg",
    },
    {
      name: "project3",
      description: "mobileapp",
      imgurl: "images/portfolio/project2.png",
    },
    {
      name: "project4",
      description: "mobileapp",
      imgurl: "images/portfolio/phone.jpg",
    },
  ],
  testimonials: [
    {
      description: "This is a sample testimonial",
      name: "Some technical guy",
    },
    {
      description: "This is a sample testimonial",
      name: "Some technical guy",
    },
  ],
};

export default resumeData;
